import { createSelector } from 'reselect';
import { AppState } from '../store';
import { DevicesState } from '../types/devices';

const selectDevicesState = (state: AppState) => state.devices;
const selectItems = (state: DevicesState) => state.items;
const selectSuccess = (state: DevicesState) => state.success;
const selectAccount = (state: DevicesState) => state.hubAccount;
const selectLoading = (state: DevicesState) => state.loading;

export const selectDevices = createSelector(
  selectDevicesState,
  selectItems,
);

export const selectSuccessState = createSelector(
  selectDevicesState,
  selectSuccess,
);

export const selectDeviceAccount = createSelector(
  selectDevicesState,
  selectAccount,
);

export const selectDeviceRequestStatus = createSelector(
  selectDevicesState,
  selectLoading,
);
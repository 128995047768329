import { 
  CREATE_DEVICE_FAILED,
  CREATE_DEVICE_REQUEST,
  CREATE_DEVICE_SUCCESS,
  DEVICES_REQUEST, 
  DEVICES_REQUEST_FAILED, 
  DEVICES_REQUEST_SUCCESS, 
  DEVICE_ACCOUNT_REQUEST, 
  DEVICE_ACCOUNT_REQUEST_FAILED, 
  DEVICE_ACCOUNT_REQUEST_SUCCESS, 
  DevicesActionTypes, 
  DevicesState 
} from "../types/devices";

const initialState: DevicesState = {
  items: [],
  total: 0,
  error: '',
  loading: false,
  selected: null,
  success: true,
  hubAccount: null
};

export default function DevicesReducer(
  state = initialState,
  action: DevicesActionTypes,
): DevicesState {
  switch (action.type) {
    case DEVICE_ACCOUNT_REQUEST:
    case CREATE_DEVICE_REQUEST:
    case DEVICES_REQUEST: 
      return {
        ...state,
        error: '',
        loading: true,
      };

    case DEVICES_REQUEST_SUCCESS: 
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };

    case DEVICE_ACCOUNT_REQUEST_SUCCESS: {
      return {
        ...state,
        hubAccount: action.payload,
        loading:false
      }
    }

    case CREATE_DEVICE_SUCCESS: 
      return {
        ...state,
        loading: false,
        success: true,
      }
    
    case DEVICE_ACCOUNT_REQUEST_FAILED:
    case CREATE_DEVICE_FAILED:
    case DEVICES_REQUEST_FAILED: 
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };

    default: {
      return state
    }
  }
}
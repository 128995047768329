import { all, call, put, takeLatest } from "redux-saga/effects";
import { 
  createDeviceRequest,
  createDeviceFailed,
  // createDeviceSuccess,
  devicesRequest, 
  devicesRequestFailed, 
  devicesRequestSuccess,
  createDeviceSuccess,
  deviceAccountRequest,
  deviceAccountRequestSuccess,
  deviceAccountRequestFailed,
} from "../actions/devices";
import { createDevice, loadDevices, loadDeviceAccount } from "../../services/devices";
import { 
  CREATE_DEVICE_REQUEST,
  DEVICES_REQUEST,
  DEVICE_ACCOUNT_REQUEST, 
} from "../types/devices";
import { createSuccessSnackBar } from "../actions/snackbars";

function* loadDevicesSaga(action: ReturnType<typeof devicesRequest>) {
  try {
    const { data } = yield call(loadDevices, action.payload);

    yield put(devicesRequestSuccess(data));
  } catch (err) {
    yield put(devicesRequestFailed(err));
  }
}

function* createDeviceRequestSaga(action: ReturnType<typeof createDeviceRequest>) {
  try {
    const { data } = yield call(createDevice, action.payload);

    yield put(createDeviceSuccess(data));
    yield put(createSuccessSnackBar(data));
  } catch (err) {
    yield put(createDeviceFailed(err));
  }
}

function* loadDeviceAccountSaga(action: ReturnType<typeof deviceAccountRequest>) {
  try {
    const { data } = yield call(loadDeviceAccount, action.payload);

    yield put(deviceAccountRequestSuccess(data));
  } catch (err) {
    yield put(deviceAccountRequestFailed(err));
  }
}

export default all([
  takeLatest(DEVICES_REQUEST, loadDevicesSaga),
  takeLatest(CREATE_DEVICE_REQUEST, createDeviceRequestSaga),
  takeLatest(DEVICE_ACCOUNT_REQUEST, loadDeviceAccountSaga),
]);
import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  contentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    wordSpacing: 4,
  },
  stepWrapper: {
    width: '60%',
    marginTop: '20px',
  },
  stepper: {
    margin: '0 50px 50px 50px',
  },
  stepIcon: {
    color: `${theme.appPalette.orange} !important`,
  },
  bookTourWrapper: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
  detailsWrapper: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
  sendButton: {
    color: 'black',
    marginTop: '100px',
    fontSize: '24px',
    fontWeight: 'bold',
    borderRadius: '15px',
  },
  description: {
    marginTop: '50px',
    fontSize: '22px',
  },
  nextButton: {
    alignSelf: 'end',
    marginTop: '50px',
  },
  navButtons: {
    marginTop: '50px',
    display: 'flex',
    gap: '10px',
    alignSelf: 'flex-end',
  },
});

import { connect } from 'react-redux';
// import { withStyles } from '@material-ui/core';
import Sidebar from './Sidebar';
// import styles from './styles';
import { selectAuthData } from '../../redux/selectors/auth';

import { AppState } from '../../redux/store';
import { selectDeviceAccount } from '../../redux/selectors/devices';
import { deviceAccountRequest } from '../../redux/actions/devices';

const mapStateToProps = (state: AppState) => ({ 
  userRole: selectAuthData(state).isPM ? 'PM' : 'USER', 
  isRental: selectAuthData(state).isRental,
  hubAccount: selectDeviceAccount(state),
});

const mapDispatchToProps = {
  loadAccount: deviceAccountRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

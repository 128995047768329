import React from 'react';
import { Device } from '../../redux/types/devices';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Spinner from '@material-ui/core/CircularProgress';
import { navTo } from '../../browserHsitory';
import {
  Typography, 
  CardMedia, 
  WithStyles, 
  ExpansionPanel, 
  Box, 
  Button, 
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './styles';
import { Query } from '../../utils/query';
import useDevices from './hook/useDevices.hook';
import adchubImg from '../../assets/img/adc_hub.png';
import Grid from '@material-ui/core/Grid';

type Props = {
  devices: Device[];
  loadDevices: (query: Query) => void;
  loading: boolean;
} & WithStyles<typeof styles>;

const Devices: React.FC<Props> = (props) => {
  const { classes, devices, loadDevices, loading } = props;
  useDevices(loadDevices);

  const getUrlForDevice = (device: Device) => {
    const serialNumber = device.UnitDescription
    .trim()
    .split(' ')
    .join('')
    .toUpperCase();
    return `${device.CustomerId}&${serialNumber}`
  }

  return(
    <>
      <ExpansionPanel
          classes={{ root: classes.expandRoot }}
          expanded={true}
        >
          <ExpansionPanelSummary>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <Typography color="textSecondary">ADC Devices</Typography>
              <Button
                id="fs-export-properties"
                size="small"
                onClick={() => {}}
              >
                Export All
              </Button>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              {devices && devices.map(device => (
                <Grid
                  item
                  xs={2}
                  sm={2}
                >
                  <Card
                    role="presentation"
                    className={classes.card}
                  >
                  <div className={classes.iconWrapper}>
                    <CardMedia
                      className={classes.icon}
                      image={adchubImg}
                    />
                  </div>
                  <CardContent>
                    <Typography className={classes.primaryText}>
                      Customer Id:
                      &nbsp;
                      <span className="hubId">{device.CustomerId}</span>
                    </Typography>
                    <Typography className={classes.secondaryText}>
                      Unit Description:
                      &nbsp;
                      <span className={classes.secondaryText}>{device.UnitDescription}</span>
                    </Typography>
                    <Typography className={classes.secondaryText}>
                      Timezone:
                      &nbsp;
                      <span className={classes.secondaryText}>{device.ZoneInfoTimeZone}</span>
                    </Typography>
                    {device.property_id ? (
                        <Typography className={classes.secondaryText}>
                          Property ID:
                          &nbsp;
                          <Link className={classes.link} onClick={(e) => e.stopPropagation()} to={`/properties/${device.property_id}`}>{device.property_id}</Link>
                        </Typography>
                      ):(
                        <Button 
                          variant="contained" 
                          color="primary" 
                          className={classes.activateButton}
                          onClick={() => !device.hub_id && navTo(`devices/${getUrlForDevice(device)}`)}
                        >
                          ACTIVATE
                        </Button>)
                    }
                  </CardContent>
                </Card>
              </Grid>
        ))}
      </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    {loading && (
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    )}
  </>
  )
}

export default Devices
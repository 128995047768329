import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  card: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    height: '100%',
  },
  iconWrapper: {
    textAlign: 'center',
    margin: '15px 0 31px',
    width: '100%',
  },
  expandRoot: {
    backgroundColor: '#fefefe',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  icon: {
    height: 250,
    fontSize: 114,
    backgroundSize: 'contain',
    color: '#DB5C0E',
    display: 'block',
  },
  primaryText: {
    color: '#DB5C0E',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '28px',
    '& .hubId': {
      fontWeight: 'bold',
      color: '#000',
    },
  },
  secondaryText: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#979797',
  },
  link: {
    color: theme.appPalette.orange,
  },
  activateButton: {
    marginTop: '40px',
    fontSize: '16px',
    fontWeight: 'bold',
    width: '100%'
  }
});

import { connect } from 'react-redux';
import { AppState } from '../../redux/store';
import { devicesRequest } from '../../redux/actions/devices';
import { selectDeviceRequestStatus, selectDevices } from '../../redux/selectors/devices';
import Devices from './Devices';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const mapStateToProps = (state: AppState) => ({
  devices: selectDevices(state),
  loading: selectDeviceRequestStatus(state)
});

const mapDispatchToProps = {
  loadDevices: devicesRequest,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Devices));
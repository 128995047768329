import * as yup from 'yup';
import { BookTour } from './types';

export const shareCustomerValidationSchema = yup.object().shape({
  firstname: yup.string().min(1).max(250).required('First Name is required'),
  lastname: yup.string().min(1).max(250).required('Last Name is required'),
  email_1: yup.string().email('Email must be valid email').required('Email is required'),
  cell_phone_1: yup.string().matches(/^\d{3}-\d{3}-\d{4}$/).required('Phone is required'),
  property_id: yup.number().min(1, 'Property is required'),
  book_tour: yup.mixed<BookTour>().oneOf(Object.values(BookTour)).required(),
  visitor_type_id: yup.number().min(1, 'Visitor Type is required'),
  visit_date: yup.date().when('$isScheduledTour', {
    is: true,
    then: yup.date().required('Visit time is required'),
    otherwise: yup.date().nullable(),
  }),
  visit_time: yup.string().when('$isScheduledTour', {
    is: true,
    then: yup.string().required('Visit time is required'),
    otherwise: yup.string().nullable(),
  }),
});

import { ErrorMessage, ValueOf } from "../../types/main";
import * as DevicesActions from '../actions/devices';

export const DEVICES_REQUEST = 'DEVICES_REQUEST';
export const DEVICES_REQUEST_SUCCESS = 'DEVICES_REQUEST_SUCCESS';
export const DEVICES_REQUEST_FAILED = 'DEVICES_REQUEST_FAILED';
export const DEVICE_ACCOUNT_REQUEST = 'DEVICE_ACCOUNT_REQUEST';
export const DEVICE_ACCOUNT_REQUEST_SUCCESS = 'DEVICE_ACCOUNT_REQUEST_SUCCESS';
export const DEVICE_ACCOUNT_REQUEST_FAILED = 'DEVICE_ACCOUNT_REQUEST_FAILED';
export const CREATE_DEVICE_REQUEST = 'CREATE_DEVICE_REQUEST';
export const CREATE_DEVICE_SUCCESS = 'CREATE_DEVICE_SUCCESS';
export const CREATE_DEVICE_FAILED = 'CREATE_DEVICE_FAILED';


export type DevicesActionTypes = ReturnType<ValueOf<typeof DevicesActions>>;

export interface DevicesState {
  items: Device[],
  total: number,
  loading: boolean,
  error: ErrorMessage['message'],
  success: boolean,
  selected: Device | null,
  hubAccount: DeviceAccount | null;
}

export interface DeviceDetails {
  CustomerId: number,
  UnitDescription: string,
  ZoneInfoTimeZone: string,
  hub_id?: boolean,
}

export interface Device {
  CustomerId: number,
  UnitDescription: string,
  ZoneInfoTimeZone: string,
  hub_id?: string,
  property_id?: string;
}

export interface DeviceInput {
  serialNumber: string,
  imei: string,
  hub_type: string,
  comments: string,
  description: string,
  property_id: number,
  customer_id?: string,
}

export interface DeviceAccount {
  id: string,
  username: string,
  email: string,
  account_type: string,
  adc_group_id: string,
  builderid: number,
}
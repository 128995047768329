import React, { useEffect } from 'react';
import { SingleSelect } from 'react-select-material-ui';
import { generateLabel } from '../../utils/helpers';
import useStyles from './styles';

type Props = {
  handleChange: (value: number, rest?: any) => void;
  getBuilderCommunities: (builderId: number) => void;
  initialValue?: number | null;
  creatable?: boolean;
  communityExternalIds: string[];
  builderCommunities: { id: number, name: string, city?: string, state?: string }[];
  builderId: number;
};

const CommunitySearch: React.FC<Props> = ({
  handleChange, initialValue, getBuilderCommunities, builderId, builderCommunities, creatable = true,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getBuilderCommunities(builderId);
  }, [getBuilderCommunities, builderId]);

  return (
    <SingleSelect
      className={classes.root}
      label={generateLabel('Community Name')}
      options={builderCommunities.map((community: {
        id: number,
        name: string,
        city?: string,
        state?: string,
        zip?: string,
        lasso_community_project_id?: string,
        novihome_integration?: string,
        community_backup_code?: string,
        trade_code?: string,
        sales_code?: string,
        community_url?: string,
        sales_agent?: string,
      }) => ({
        value: community.id,
        label: community.name,
        city: community.city || '',
        state: community.state || '',
        zipcode: community.zip || '',
        lasso_community_project_id: community.lasso_community_project_id,
        novihome_integration: community.novihome_integration,
        community_backup_code: community.community_backup_code,
        trade_code: community.trade_code,
        sales_code: community.sales_code,
        url: community.community_url,
        sales_agent: community.sales_agent,
      }))}
      onChange={handleChange}
      value={initialValue}
      SelectProps={{
        isCreatable: creatable,
        msgNoOptionsMatchFilter: 'No community names was found',
      }}
    />
  );
};

export default CommunitySearch;

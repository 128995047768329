import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  PROPERTIES_REQUEST,
  PROPERTIES_EXPORT_REQUEST,
  PROPERTIES_EXPORT_AND_PRINT_REQUEST,
  PROPERTY_DETAILS_REQUEST,
  PROPERTY_ADD_AGENT_REQUEST,
  SAVE_PROPERTY,
  PROPERTY_REMOVE_AGENT_REQUEST,
  SAVE_PROJECT_ID,
  CREATE_PROPERTY, PropertiesExportRequestActionType,
  PROPERTY_TOURS_REPORT_REQUEST,
  PropertiesVisitsReportExportRequestActionType,
  PROPERTY_VISITS_EXPORT_AND_PRINT_REQUEST,
  PROPERTY_VISITS_REPORT_EXPORT_REQUEST,
  PROPERTIES_BY_COMMUNITY_REQUEST,
  PROPERTIES_BY_BUILDER_REQUEST,
} from '../types/properties';

import { exportToFile } from '../../services/export';
import { openPrintDialog } from '../../services/print';
import {
  loadProperties,
  loadPropertyById,
  loadPropertiesExport,
  propertyAddAgent,
  propertyRemoveAgent,
  savePropertyData,
  createPropertyData,
  saveProjectIdData,
  loadPropertyToursReport,
  loadPropertyVisitsReportExport,
  loadPropertiesByCommunity,
  loadPropertiesByBuilder,
} from '../../services/properties';
import {
  propertiesRequestSuccess,
  propertiesRequestFailed,
  propertiesExportSuccess,
  propertiesExportRequestFailed,
  propertyAddAgentRequest,
  propertyAddAgentSuccess,
  propertyAddAgentFailed,
  propertyRemoveAgentRequest,
  propertyDetailsRequestSuccess,
  propertyDetailsRequestFailed,
  saveProperty,
  createProperty,
  saveProjectId,
  propertyRemoveAgentSuccess,
  propertyRemoveAgentFailed,
  propertiesRequest,
  propertyDetailsRequest,
  propertyToursReportSuccess,
  propertyToursReportFailed,
  propertyToursReportExportSuccess,
  propertyToursReportExportFailed,
  propertiesByCommunityRequestSuccess,
  propertiesByCommunityRequestFailed,
  propertiesByCommunityRequest,
  propertiesByBuilderRequestSuccess,
  propertiesByBuilderRequestFailed,
} from '../actions/properties';
import { createErrorSnackBar, createSuccessSnackBar, createInfoSnackBar } from '../actions/snackbars';
import { navTo } from '../../browserHsitory';

function* loadPropertiesSaga(action: ReturnType<typeof propertiesRequest>) {
  try {
    const { data } = yield call(loadProperties, action.payload);
    yield put(propertiesRequestSuccess(data));
  } catch (err) {
    yield put(propertiesRequestFailed(err));
  }
}

function* loadPropertyDetailsSaga(action: ReturnType<typeof propertyDetailsRequest>) {
  try {
    const { data } = yield call(loadPropertyById, action.payload);
    yield put(propertyDetailsRequestSuccess(data));
  } catch (err) {
    yield put(propertyDetailsRequestFailed(err));
  }
}

function* loadPropertiesByCommunitySaga(action: ReturnType<typeof propertiesByCommunityRequest>) {
  try {
    const { data } = yield call(loadPropertiesByCommunity, action.payload);
    yield put(propertiesByCommunityRequestSuccess(data));
  } catch (err) {
    yield put(propertiesByCommunityRequestFailed(err));
  }
}

function* loadPropertiesExportSaga(action: PropertiesExportRequestActionType) {
  try {
    const { selection, mimeType } = action.payload;
    const { data } = yield call(loadPropertiesExport, selection, mimeType);
    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    if (blob && action.type === PROPERTIES_EXPORT_AND_PRINT_REQUEST) {
      openPrintDialog(blob);
    } else {
      const fileName = 'properties-export.csv';

      yield call(exportToFile, blob, fileType, fileName);
    }

    yield put(propertiesExportSuccess());
  } catch (err) {
    yield put(propertiesExportRequestFailed(err));
  }
}

function* propertiesAddAgentSaga(
  action: ReturnType<typeof propertyAddAgentRequest>,
) {
  try {
    const { data } = yield call(propertyAddAgent, action.payload);

    yield put(createSuccessSnackBar('Agent added..'));
    yield put(propertyAddAgentSuccess(data));
  } catch (err) {
    yield put(propertyAddAgentFailed(err));
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

function* propertiesRemoveAgentSaga(
  action: ReturnType<typeof propertyRemoveAgentRequest>,
) {
  try {
    yield call(propertyRemoveAgent, action.payload);
    yield put(createInfoSnackBar('Agent removed.'));
    yield put(propertyRemoveAgentSuccess(action.payload.agentId));
  } catch (err) {
    yield put(propertyRemoveAgentFailed(err));
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

function* savePropertySaga(action: ReturnType<typeof saveProperty>) {
  try {
    const { data } = yield call(savePropertyData, action.payload);
    yield put(createSuccessSnackBar(data));
    yield put(propertyDetailsRequest(action.payload.property_id));
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

function* createPropertySaga(action: ReturnType<typeof createProperty>) {
  try {
    const { data } = yield call(createPropertyData, action.payload);
    yield put(createSuccessSnackBar(data.message));
    yield call(navTo, `/properties/${data.property_id}`);
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

function* saveProjectIdSaga(action: ReturnType<typeof saveProjectId>) {
  try {
    const { data } = yield call(saveProjectIdData, action.payload);
    yield put(createSuccessSnackBar(data));
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

function* loadPropertyToursReportExportSaga(action: PropertiesVisitsReportExportRequestActionType) {
  try {
    const {
      selected, startDate, endDate, mimeType,
    } = action.payload;
    const { data } = yield call(loadPropertyVisitsReportExport, selected, startDate, endDate, mimeType);

    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    if (blob && action.type === PROPERTY_VISITS_EXPORT_AND_PRINT_REQUEST) {
      openPrintDialog(blob);
    } else {
      const fileName = 'properties_tourReport-export.csv';

      yield call(exportToFile, blob, fileType, fileName);
    }

    yield put(propertyToursReportExportSuccess());
  } catch (err) {
    yield put(propertyToursReportExportFailed(err));
  }
}

function* loadPropertyToursReportSaga(action: any) {
  try {
    const { data } = yield call(loadPropertyToursReport, action.payload);
    yield put(propertyToursReportSuccess(data));
  } catch (err) {
    yield put(propertyToursReportFailed(err));
  }
}

function* loadPropertiesByBuilderSaga() {
  try {
    const { data } = yield call(loadPropertiesByBuilder);
    yield put(propertiesByBuilderRequestSuccess(data));
  } catch (err) {
    yield put(propertiesByBuilderRequestFailed(err));
  }
}

export default all([
  takeLatest<any>(PROPERTY_ADD_AGENT_REQUEST, propertiesAddAgentSaga),
  takeLatest<any>(PROPERTY_REMOVE_AGENT_REQUEST, propertiesRemoveAgentSaga),
  takeLatest<any>(PROPERTIES_REQUEST, loadPropertiesSaga),
  takeLatest<any>(PROPERTY_DETAILS_REQUEST, loadPropertyDetailsSaga),
  takeLatest<any>(PROPERTIES_BY_COMMUNITY_REQUEST, loadPropertiesByCommunitySaga),
  takeLatest<any>(
    [PROPERTIES_EXPORT_REQUEST, PROPERTIES_EXPORT_AND_PRINT_REQUEST],
    loadPropertiesExportSaga,
  ),
  takeLatest<any>(SAVE_PROPERTY, savePropertySaga),
  takeLatest<any>(SAVE_PROJECT_ID, saveProjectIdSaga),
  takeLatest<any>(CREATE_PROPERTY, createPropertySaga),
  takeLatest<any>(PROPERTY_TOURS_REPORT_REQUEST, loadPropertyToursReportSaga),

  takeLatest<any>(
    [PROPERTY_VISITS_REPORT_EXPORT_REQUEST, PROPERTY_VISITS_EXPORT_AND_PRINT_REQUEST],
    loadPropertyToursReportExportSaga,
  ),
  takeLatest<any>(PROPERTIES_BY_BUILDER_REQUEST, loadPropertiesByBuilderSaga),
]);

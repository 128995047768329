import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from './styles';

import { customerShareRequest } from '../../redux/actions/customers';
import { propertiesByBuilderRequest } from '../../redux/actions/properties';
import { AppState } from '../../redux/store';
import { selectBuilderProperties } from '../../redux/selectors/properties';

import Share from './Share';

const mapStateToProps = (state: AppState) => ({
  properties: selectBuilderProperties(state),
});

const mapDispatchToProps = {
  shareCustomer: customerShareRequest,
  loadBuilderProperties: propertiesByBuilderRequest,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Share),
);

import { propertiesByCommunityRequest, saveProperty } from "../../../redux/actions/properties";
import { createDeviceRequest } from "../../../redux/actions/devices";
import { getBuilderId } from '../../../redux/selectors/auth';
import { AppState } from '../../../redux/store';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import AddForm from './AddForm';
import styles from './styles';

const mapStateToProps = (state: AppState) => ({
  builderId: getBuilderId(state),
});

const mapDispatchToProps = {
  loadCommunityProperties: propertiesByCommunityRequest,
  createDevice: createDeviceRequest,
  updateProperty: saveProperty,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddForm));
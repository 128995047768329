import React, { useEffect, useState } from 'react';
import CommunitySearch from "../../CommunitySearch";
import PropertySearch from "../../PropertySearch";
import { WithStyles } from '@material-ui/styles';
import { ArrowLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import styles from './styles';
import CustomButton from '../../../components/CustomButton';
import TextField from '@material-ui/core/TextField';
import {
  Grid,
  Button,
  Paper,
  StepLabel,
  Box,
  Stepper,
  Step,
  StepConnector,
  withStyles,
} from '@material-ui/core';
import { DeviceInput } from '../../../redux/types/devices';
import { getPropertyCoordinates } from '../../../services/properties';
import { PropertyInput } from '../../../redux/types/properties';
import { navTo } from '../../../browserHsitory';

type Props = {
  loadCommunityProperties: (communityId: number) => void;
  createDevice: (device: DeviceInput) => void;
  updateProperty: (property: PropertyInput) => void;
  builderId: number;
} & WithStyles<typeof styles>;

const Connector = withStyles({
  alternativeLabel: { top: 9 },
  active: { '& $line': { borderColor: '#DB5C0E' } },
  completed: { '& $line': { borderColor: '#DB5C0E' } },
  line: {
    borderTopWidth: 2,
  },
  vertical: {
    marginLeft: 9,
  },
  lineVertical: {
    borderLeftWidth: 2,
  },
})(StepConnector);

const AddForm: React.FC<Props> = props => {
  const { 
    loadCommunityProperties,
    builderId,
    classes,
    createDevice,
    updateProperty,
  } = props;

  const history = useHistory();

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [property, setProperty] = useState<any>(null);
  const [device, setDevice] = useState<DeviceInput>({
    serialNumber: '',
    imei: '',
    hub_type: 'ADC',
    comments: '',
    description: '',
    property_id: 0,
    customer_id: history.location.pathname.split('/')[2],
  });

  const steps = [
    'Select Property',
    'Activate'
  ];

  useEffect(() => {
    const deviceFields = history.location.pathname.split('/')[2];
    const indexOfSplit = deviceFields.indexOf("&");
    setDevice({
      ...device,
      customer_id: deviceFields.slice(0, indexOfSplit), 
      serialNumber: deviceFields.slice(indexOfSplit + 1),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeCommunity = (value: number, rest?: any) => {
    loadCommunityProperties(value);
  }

  const getCoordinates = async () => {
    const coordinates = await getPropertyCoordinates({
      address_1: property.address_1,
      houseNum: property.house_num,
      city: property.city,
      state: property.state,
    });
    updateProperty({
      ...property,
      ...(coordinates?.data.lat && { latitude: coordinates.data.lat }),
      ...(coordinates?.data.lng && { longitude: coordinates.data.lng }),
      disposition_id: 'PROPERTY_AVAILABLE',
    });
    navTo('/devices')
  }

  const handleSaveClick = () => {
    createDevice(device);
    setPageIndex(pageIndex + 1);
  }

  const handleChangeProperty = (value: number, rest?: any) => {
    setProperty({
      property_id: value,
      address_1: rest.address,
      city: rest.city,
      house_num: rest.lot_number,
      state: rest.state,
    });
    setDevice({
      ...device,
      property_id: value
    });
  }

  return (
    <>
      <div>
        <Grid container justify="space-between" style={{ marginBottom: 25 }}>
          <Grid item>
            <Button variant="contained" onClick={() => history.goBack()}>
              <ArrowLeft />
              {' GoBack'}
            </Button>
          </Grid>
        </Grid>
        <Paper className={classes.paper}>
          <div className={classes.headerText}>
            <span>Device Wizard</span>
          </div>
          <Box style={{ width: '60%', marginLeft: '20%' }}>
            <Stepper
              alternativeLabel
              className={classes.stepper}
              activeStep={pageIndex}
              connector={<Connector />}
            >
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.stepIcon,
                          completed: classes.stepIcon,
                        },
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          {pageIndex === 0 && (
            <div className={classes.inputForm}>
              <h3 style={{marginBottom: '20px', marginTop: '10px'}}>Select Community</h3>
              <CommunitySearch
                handleChange={handleChangeCommunity}
                builderId={builderId}
                creatable={false}
              />
              <h3 style={{marginBottom: '20px', marginTop: '10px'}}>Select Property</h3>
              <PropertySearch
                handleChange={handleChangeProperty}
                activeFilter={true}
              />
              <div className={classes.navButtons}>
                <CustomButton
                  disabled={!device.property_id}
                  variant="orange"
                  onClick={handleSaveClick}
                >
                  Save Hub
                </CustomButton>
              </div>
            </div>
          )}
          {pageIndex === 1 && (
            <>
            <div className={classes.inputForm}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    margin="normal"
                    id="address_1"
                    name="address"
                    label="Address"
                    value={property?.address_1}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    margin="normal"
                    id="city"
                    name="city"
                    label="City"
                    value={property?.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    margin="normal"
                    id="state"
                    name="state"
                    label="State"
                    value={property?.state}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    margin="normal"
                    id="house_num"
                    name="house_num"
                    label="Lot Number"
                    value={property?.house_num}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.navButtons}>
              <CustomButton
                variant="orange"
                onClick={getCoordinates}
              >
                Activate
              </CustomButton>
            </div>
          </>
          )}
        </Paper>
      </div>
    </>
  )
}

export default AddForm;
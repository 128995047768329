import {
  PROPERTIES_REQUEST,
  PROPERTIES_REQUEST_SUCCESS,
  PROPERTIES_REQUEST_FAILED,
  PROPERTIES_EXPORT_REQUEST,
  PROPERTIES_EXPORT_REQUEST_SUCCESS,
  PROPERTIES_EXPORT_REQUEST_FAILED,
  PROPERTIES_EXPORT_AND_PRINT_REQUEST,
  PROPERTY_DETAILS_REQUEST,
  PROPERTY_DETAILS_REQUEST_FAILED,
  PROPERTY_DETAILS_REQUEST_SUCCESS,
  PROPERTIES_BY_COMMUNITY_REQUEST,
  PROPERTIES_BY_COMMUNITY_REQUEST_FAILED,
  PROPERTIES_BY_COMMUNITY_REQUEST_SUCCESS,
  PROPERTY_ADD_AGENT_REQUEST,
  PROPERTY_ADD_AGENT_REQUEST_SUCCESS,
  PROPERTY_ADD_AGENT_REQUEST_FAILED,
  PROPERTY_REMOVE_AGENT_REQUEST,
  SAVE_PROPERTY,
  PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS,
  PROPERTY_REMOVE_AGENT_REQUEST_FAILED,
  SAVE_PROJECT_ID,
  CREATE_PROPERTY,
  PropertiesRequestSuccess,
  PropertyDetails,
  PropertyDetailsAgent,
  PropertyInput,
  DataToSave,
  PROPERTY_TOURS_REPORT_REQUEST,
  PROPERTY_TOURS_REPORT_REQUEST_SUCCESS,
  PROPERTY_TOURS_REPORT_REQUEST_FAILED,

  PROPERTY_VISITS_REPORT_EXPORT_REQUEST,
  PROPERTY_VISITS_REPORT_EXPORT_REQUEST_SUCCESS,
  PROPERTY_VISITS_REPORT_EXPORT_REQUEST_FAILED,

  PROPERTY_VISITS_EXPORT_AND_PRINT_REQUEST,
  PROPERTY_VISITS_EXPORT_REQUEST,
  PROPERTY_VISITS_EXPORT_REQUEST_SUCCESS,
  PROPERTY_VISITS_EXPORT_REQUEST_FAILED,
  Property,
  PROPERTIES_BY_BUILDER_REQUEST,
  PROPERTIES_BY_BUILDER_REQUEST_SUCCESS,
  PROPERTIES_BY_BUILDER_REQUEST_FAILED,
} from '../types/properties';
import { Query } from '../../utils/query';
import { MimeType } from '../types/mime';
import { ErrorMessage } from '../../types/main';

export const propertiesRequest = (query?: Query) => ({
  type: PROPERTIES_REQUEST,
  payload: query,
} as const);

export const propertiesRequestFailed = (payload: ErrorMessage) => ({
  type: PROPERTIES_REQUEST_FAILED,
  payload,
} as const);

export const propertiesRequestSuccess = (payload: PropertiesRequestSuccess) => ({
  type: PROPERTIES_REQUEST_SUCCESS,
  payload,
} as const);

export const propertiesExportRequest = (selection: Array<number | string>, mimeType: MimeType = 'text/csv') => ({
  type: PROPERTIES_EXPORT_REQUEST,
  payload: {
    selection,
    mimeType,
  },
} as const);

export const propertiesExportRequestFailed = (payload: ErrorMessage) => ({
  type: PROPERTIES_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const propertiesExportAndPrintRequest = (
  selection: Array<number | string>,
  mimeType: MimeType = 'application/pdf',
) => ({
  type: PROPERTIES_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selection,
    mimeType,
  },
} as const);

export const propertiesExportSuccess = () => ({
  type: PROPERTIES_EXPORT_REQUEST_SUCCESS,
} as const);

export const propertyDetailsRequest = (payload: number | string) => ({
  type: PROPERTY_DETAILS_REQUEST,
  payload,
} as const);

export const propertyDetailsRequestFailed = (payload: ErrorMessage) => ({
  type: PROPERTY_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const propertyDetailsRequestSuccess = (payload: PropertyDetails) => ({
  type: PROPERTY_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export const propertiesByCommunityRequest = (payload: number | string) => ({
  type: PROPERTIES_BY_COMMUNITY_REQUEST,
  payload,
} as const);

export const propertiesByCommunityRequestFailed = (payload: ErrorMessage) => ({
  type: PROPERTIES_BY_COMMUNITY_REQUEST_FAILED,
  payload,
} as const);

export const propertiesByCommunityRequestSuccess = (payload: Property[]) => ({
  type: PROPERTIES_BY_COMMUNITY_REQUEST_SUCCESS,
  payload,
} as const);

export const propertyAddAgentRequest = (
  payload: { propertyId: number | string, agentId: string, is_project_manager: boolean | number, email: string, phone: string },
) => ({
  type: PROPERTY_ADD_AGENT_REQUEST,
  payload,
} as const);

export const propertyAddAgentFailed = (payload: ErrorMessage) => ({
  type: PROPERTY_ADD_AGENT_REQUEST_FAILED,
  payload,
} as const);

export const propertyAddAgentSuccess = (agent: PropertyDetailsAgent) => ({
  type: PROPERTY_ADD_AGENT_REQUEST_SUCCESS,
  payload: agent,
} as const);

export const propertyRemoveAgentRequest = (
  payload: { propertyId: number | string, agentId: string },
) => ({
  type: PROPERTY_REMOVE_AGENT_REQUEST,
  payload,
} as const);

export const propertyRemoveAgentFailed = (payload: ErrorMessage) => ({
  type: PROPERTY_REMOVE_AGENT_REQUEST_FAILED,
  payload,
} as const);

export const propertyRemoveAgentSuccess = (agentID: string) => ({
  type: PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS,
  payload: agentID,
} as const);

export const saveProperty = (property: PropertyInput) => ({
  type: SAVE_PROPERTY,
  payload: property,
} as const);

export const createProperty = (property: PropertyInput) => ({
  type: CREATE_PROPERTY,
  payload: property,
} as const);

export const saveProjectId = (data: DataToSave) => ({
  type: SAVE_PROJECT_ID,
  payload: data,
} as const);

export const propertyToursReportRequest = (query: any) => ({
  type: PROPERTY_TOURS_REPORT_REQUEST,
  payload: query,
} as const);

export const propertyToursReportSuccess = (payload: any) => ({
  type: PROPERTY_TOURS_REPORT_REQUEST_SUCCESS,
  payload,
} as const);

export const propertyToursReportFailed = (payload: any) => ({
  type: PROPERTY_TOURS_REPORT_REQUEST_FAILED,
  payload,
} as const);

export const propertyTourExportAndPrintRequest = (selected: Array<string | number>, startDate: Date, endDate: Date, mimeType: MimeType = 'application/pdf') => ({
  type: PROPERTY_VISITS_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selected,
    startDate,
    endDate,
    mimeType,
  },
} as const);

export const propertyToursExportRequest = (selected: Array<string | number>, mimeType: MimeType = 'text/csv') => ({
  type: PROPERTY_VISITS_EXPORT_REQUEST,
  payload: {
    selected,
    mimeType,
  },
} as const);

export const propertyToursExportSuccess = () => ({
  type: PROPERTY_VISITS_EXPORT_REQUEST_SUCCESS,
} as const);

export const propertyToursExportFailed = (payload: ErrorMessage) => ({
  type: PROPERTY_VISITS_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const propertyToursReportExportRequest = (selected: Array<string | number>, startDate: Date, endDate: Date, mimeType: MimeType = 'text/csv') => ({
  type: PROPERTY_VISITS_REPORT_EXPORT_REQUEST,
  payload: {
    selected,
    startDate,
    endDate,
    mimeType,
  },
} as const);


export const propertyToursReportExportSuccess = () => ({
  type: PROPERTY_VISITS_REPORT_EXPORT_REQUEST_SUCCESS,
} as const);

export const propertyToursReportExportFailed = (payload: ErrorMessage) => ({
  type: PROPERTY_VISITS_REPORT_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const propertiesByBuilderRequest = () => ({
  type: PROPERTIES_BY_BUILDER_REQUEST,
} as const);

export const propertiesByBuilderRequestFailed = (payload: ErrorMessage) => ({
  type: PROPERTIES_BY_BUILDER_REQUEST_FAILED,
  payload,
} as const);

export const propertiesByBuilderRequestSuccess = (payload: Property[]) => ({
  type: PROPERTIES_BY_BUILDER_REQUEST_SUCCESS,
  payload,
} as const);

import React from 'react';
import { Action } from 'redux';
import { Order } from '../utils/table';
import { Customer } from '../redux/types/customers';
import { Visit } from '../redux/types/visits';
import { Offer } from '../redux/types/offers';
import { Property } from '../redux/types/properties';
import { Agent } from '../redux/types/agents';
import { Community } from '../redux/types/communities';
import { ROWS_PER_PAGE } from '../constants/table';
import { ScheduledVisit } from '../redux/types/scheduledVisit';
import { SearchSortActionTypes } from './constants';
import { SearchSortContextActionType } from './types';
import { VisitsAfterTourHours } from '../redux/types/afterTourHours';
import { Device } from '../redux/types/devices';

export type Fields<T> = {
  currentOrder: Order,
  currentOrderBy: keyof T,
  contextSearch: string,
  currentPage: number,
  rowsPerPageAmount: number,
  lockDisposition?: number,
  lock_status?: string,
  filter?: number,
  filterByBuilder?: number| null
};
export type initialState = {
  customers: Fields<Customer>,
  visits: Fields<Visit>,
  scheduledVisits: Fields<ScheduledVisit>,
  properties: Fields<Property>,
  agents: Fields<Agent>,
  communities: Fields<Community>,
  offers: Fields<Offer>,
  afterTourHours: Fields<VisitsAfterTourHours>,
  devices: Fields<Device>,
  [key: string]: Fields<any>
};
export const initialState: initialState = {
  customers: {
    currentOrder: 'desc',
    currentOrderBy: 'last_visit_date',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  visits: {
    currentOrder: 'desc',
    currentOrderBy: 'modified_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  reports: {
    currentOrder: 'asc',
    currentOrderBy: 'community',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  scheduledVisits: {
    currentOrder: 'desc',
    currentOrderBy: 'visit_date',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  properties: {
    currentOrder: 'desc',
    currentOrderBy: 'modified_on',
    contextSearch: '',
    currentPage: 0,
    lockDisposition: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
    lock_status: 'Active',
  },
  teams: {
    currentOrder: 'desc',
    currentOrderBy: 'created_on',
    contextSearch: '',
    currentPage: 0,
    lockDisposition: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  agents: {
    currentOrder: 'desc',
    currentOrderBy: 'firstname',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
    filterByBuilder: null,
  },
  communities: {
    currentOrder: 'desc',
    currentOrderBy: 'modified_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
    filterByBuilder: null,
  },
  offers: {
    currentOrder: 'desc',
    currentOrderBy: 'move_in',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  afterTourHours: {
    currentOrder: 'desc',
    currentOrderBy: 'modified_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  webhooks: {
    currentOrder: 'desc',
    currentOrderBy: 'created_on',
    contextSearch: '',
    currentPage: 0,
    lockDisposition: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  devices: {
    currentOrder: 'desc',
    currentOrderBy: 'CustomerId',
    contextSearch: '',
    currentPage: 0,
    lockDisposition: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
};

export const SearchSortContext = React.createContext<{
  state: typeof initialState;
  dispatch:(action: Action<SearchSortContextActionType> & { payload?: any }) => void;
}>({
      state: initialState,
      dispatch: () => { },
    });


export const searchSortReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SearchSortActionTypes.CHANGE_CUSTOMER: {
      return {
        ...state,
        customers: action.payload,
      };
    }

    case SearchSortActionTypes.CHANGE_VISIT: {
      return {
        ...state,
        visits: action.payload,
      };
    }

    case SearchSortActionTypes.CHANGE_VISITS_REPORT: {
      return {
        ...state,
        visitsReport: action.payload,
      };
    }

    case SearchSortActionTypes.CHANGE_SCHEDULED_VISIT: {
      return {
        ...state,
        scheduledVisits: action.payload,
      };
    }

    case SearchSortActionTypes.CHANGE_PROPERTY: {
      return {
        ...state,
        properties: action.payload,
      };
    }
    case SearchSortActionTypes.CHANGE_TEAM: {
      return {
        ...state,
        teams: action.payload,
      };
    }
    case SearchSortActionTypes.CHANGE_AGENT: {
      return {
        ...state,
        agents: action.payload,
      };
    }
    case SearchSortActionTypes.CHANGE_COMMUNITY: {
      return {
        ...state,
        agents: action.payload,
      };
    }
    case SearchSortActionTypes.CHANGE_OFFER: {
      return {
        ...state,
        offers: action.payload,
      };
    }
    case SearchSortActionTypes.CHANGE_VISITS_AFTER_TOUR_HOURS: {
      return {
        ...state,
        afterTourHours: action.payload,
      };
    }
    case SearchSortActionTypes.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

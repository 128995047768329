import axios from './axios';
import { Query, buildQuery } from "../utils/query";
import { DeviceInput } from '../redux/types/devices';

export const loadDevices = (queryObject?: Query) => {

  const query = buildQuery(queryObject);

  return axios.get(`/hubs/adc-devices${query}`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  });
};

export const createDevice = (device: DeviceInput) => {
  return axios.post(`/hubs/adc-devices`, device, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  });
};

export const loadDeviceAccount = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/hubs/adc-account${query}`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  });
};
import SurveyResult from './survey';
import { ErrorMessage, ValueOf } from '../../types/main';
import * as CustomersActions from '../actions/customers';
import { BookTour } from '../../containers/Share/types';

export const CUSTOMER_DETAILS_REQUEST = 'CUSTOMER_DETAILS_REQUEST';
export const CUSTOMER_DETAILS_REQUEST_SUCCESS = 'CUSTOMER_DETAILS_REQUEST_SUCCESS';
export const CUSTOMER_DETAILS_REQUEST_FAILED = 'CUSTOMER_DETAILS_REQUEST_FAILED';

export const CUSTOMERS_REQUEST = 'CUSTOMERS_REQUEST';
export const CUSTOMERS_REQUEST_SUCCESS = 'CUSTOMERS_REQUEST_SUCCESS';
export const CUSTOMERS_REQUEST_FAILED = 'CUSTOMERS_REQUEST_FAILED';

export const CUSTOMERS_EXPORT_REQUEST = 'CUSTOMERS_EXPORT_REQUEST';
export const CUSTOMERS_EXPORT_REQUEST_SUCCESS = 'CUSTOMERS_EXPORT_REQUEST_SUCCESS';
export const CUSTOMERS_EXPORT_REQUEST_FAILED = 'CUSTOMERS_EXPORT_REQUEST_FAILED';

export const CUSTOMERS_EXPORT_AND_PRINT_REQUEST = 'CUSTOMERS_EXPORT_AND_PRINT_REQUEST';
export const CUSTOMER_SELECTED_CLEAR = 'CUSTOMER_SELECTED_CLEAR';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_REQUEST_SUCCESS = 'UPDATE_CUSTOMER_REQUEST_SUCCESS';
export const UPDATE_CUSTOMER_REQUEST_FAILED = 'UPDATE_CUSTOMER_REQUEST_FAILED';

export const CUSTOMER_SHARE_REQUEST = 'CUSTOMER_SHARE_REQUEST';
export const CUSTOMER_SHARE_REQUEST_SUCCESS = 'CUSTOMER_SHARE_REQUEST_SUCCESS';
export const CUSTOMER_SHARE_REQUEST_FAILED = 'CUSTOMER_SHARE_REQUEST_FAILED';

export type CustomersActionsTypes = ReturnType<ValueOf<typeof CustomersActions>>;

export type CustomersExportRequestActionType =
ReturnType<typeof CustomersActions.customersExportRequest>
| ReturnType<typeof CustomersActions.customersExportAndPrintRequest>;

export interface CustomerVisitedProperties {
  property_id: number
  count: number
}

type VisitorContactStatusIdValue = 'Contact' | 'NO-Contact';

export interface Customer {
  customer_id: number
  firstname: string
  lastname: string
  email_1: string
  visitor_status_id: number
  visitor_status_id_value: string
  visitor_type_id: number
  visitor_type_id_value: string
  cell_phone_1: string
  created_on: string
  modified_on: string
  last_visit_property_id: number
  last_visit_date: string
  visits_count: number
  platform: string
  visitor_contact_status_id_value: VisitorContactStatusIdValue
  timezoneAbbr: number
  'customer.modified_on'?: string
}

export interface VisitedPropertyAgent {
  agent_username: string
  is_project_manager: number
  firstname: string
  lastname: string
}

export interface CustomerVisits {
  property_id: number
  remarks: string
  modified_on: string
  lot_num: string
  house_num: string
  address_1: string
  city: string
  state: string
  customer_visit_id: number
  visit_status_id: number
  visit_status_id_value: string
  builder: string
  builder_id: number
  agents: VisitedPropertyAgent[]
  propertyTimezone: number
  propTimezoneValue?: string
  attribution: string
}

export interface CustomerInfo {
  customer_id: number
  firstname: string
  lastname: string
  email_1: string
  email_2: string
  visitor_status_id: number
  visitor_type_id: number
  visitor_status_id_value: string
  visitor_type_id_value: string
  visitor_contact_status_id_value: VisitorContactStatusIdValue
  cell_phone_1: string
  cell_phone_2: string
  street_address: string
  city: string
  state: string
  zipcode: string
}

export interface CustomerDetails {
  info: CustomerInfo
  visits: CustomerVisits[]
  surveyResults: SurveyResult[] | null
}

export type CustomerDetailsRequestSuccess = CustomerDetails;

export interface CustomersRequestSuccess {
  items: Customer[]
  total: number;
}

export interface CustomersState {
  items: Customer[]
  total: number
  loading: boolean
  error: ErrorMessage['message']
  selected: CustomerDetails | null
}

export interface UpdateCustomer {
  customer_id: number
  visitor_status_id?: number
}

export interface ShareCustomer {
  firstname: string;
  lastname: string;
  email_1: string;
  cell_phone_1: string;
  property_id: number;
  visitor_type_id: number;
  book_tour?: BookTour;
  visit_date?: Date;
  visit_time?: string;
}

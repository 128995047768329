import { ErrorMessage } from "../../types/main";
import { Query } from "../../utils/query";
import { 
  CREATE_DEVICE_FAILED,
  CREATE_DEVICE_REQUEST,
  CREATE_DEVICE_SUCCESS,
  DEVICES_REQUEST, 
  DEVICES_REQUEST_FAILED, 
  DEVICES_REQUEST_SUCCESS, 
  DEVICE_ACCOUNT_REQUEST, 
  DEVICE_ACCOUNT_REQUEST_FAILED, 
  DEVICE_ACCOUNT_REQUEST_SUCCESS, 
  DeviceInput
} from "../types/devices";

export const devicesRequest = (query?: Query) => ({
  type: DEVICES_REQUEST,
  payload: query,
} as const);

export const devicesRequestFailed = (payload: ErrorMessage) => ({
  type: DEVICES_REQUEST_FAILED,
  payload,
} as const);

export const devicesRequestSuccess = (payload: any) => ({
  type: DEVICES_REQUEST_SUCCESS,
  payload,
} as const);

export const createDeviceRequest = (payload: DeviceInput) => ({
  type: CREATE_DEVICE_REQUEST,
  payload,
} as const);

export const createDeviceSuccess = (payload: any) => ({
  type: CREATE_DEVICE_SUCCESS,
  payload,
} as const);

export const createDeviceFailed = (payload: ErrorMessage) => ({
  type: CREATE_DEVICE_FAILED,
  payload,
} as const);


export const deviceAccountRequest = (payload: any) => ({
  type: DEVICE_ACCOUNT_REQUEST,
  payload,
} as const);

export const deviceAccountRequestSuccess = (payload: any) => ({
  type: DEVICE_ACCOUNT_REQUEST_SUCCESS,
  payload,
} as const);

export const deviceAccountRequestFailed = (payload: ErrorMessage) => ({
  type: DEVICE_ACCOUNT_REQUEST_FAILED,
  payload,
} as const);
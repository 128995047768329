import { ErrorMessage, ValueOf } from '../../types/main';
import * as PropertiesActions from '../actions/properties';
import { Agent } from './agents';
import { TourHoursType, WeeklyTourHours } from './communities';

export const PROPERTIES_REQUEST = 'PROPERTIES_REQUEST';
export const PROPERTIES_REQUEST_SUCCESS = 'PROPERTIES_REQUEST_SUCCESS';
export const PROPERTIES_REQUEST_FAILED = 'PROPERTIES_REQUEST_FAILED';

export const PROPERTIES_EXPORT_REQUEST = 'PROPERTIES_EXPORT_REQUEST';
export const PROPERTIES_EXPORT_REQUEST_SUCCESS = 'PROPERTIES_EXPORT_REQUEST_SUCCESS';
export const PROPERTIES_EXPORT_REQUEST_FAILED = 'PROPERTIES_EXPORT_REQUEST_FAILED';

export const PROPERTY_DETAILS_REQUEST = 'PROPERTY_DETAILS_REQUEST';
export const PROPERTY_DETAILS_REQUEST_SUCCESS = 'PROPERTY_DETAILS_REQUEST_SUCCESS';
export const PROPERTY_DETAILS_REQUEST_FAILED = 'PROPERTY_DETAILS_REQUEST_FAILED';

export const PROPERTIES_BY_COMMUNITY_REQUEST = 'PROPERTIES_BY_COMMUNITY_REQUEST';
export const PROPERTIES_BY_COMMUNITY_REQUEST_SUCCESS = 'PROPERTIES_BY_COMMUNITY_REQUEST_SUCCESS';
export const PROPERTIES_BY_COMMUNITY_REQUEST_FAILED = 'PROPERTIES_BY_COMMUNITY_REQUEST_FAILED';

export const PROPERTY_ADD_AGENT_REQUEST = 'PROPERTY_ADD_AGENT_REQUEST';
export const PROPERTY_ADD_AGENT_REQUEST_SUCCESS = 'PROPERTY_ADD_AGENT_REQUEST_SUCCESS';
export const PROPERTY_ADD_AGENT_REQUEST_FAILED = 'PROPERTY_ADD_AGENT_REQUEST_FAILED';

export const PROPERTY_REMOVE_AGENT_REQUEST = 'PROPERTY_REMOVE_AGENT_REQUEST';
export const PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS = 'PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS';
export const PROPERTY_REMOVE_AGENT_REQUEST_FAILED = 'PROPERTY_REMOVE_AGENT_REQUEST_FAILED';

export const PROPERTIES_EXPORT_AND_PRINT_REQUEST = 'PROPERTIES_EXPORT_AND_PRINT_REQUEST';
export const SAVE_PROPERTY = 'SAVE_PROPERTY';
export const CREATE_PROPERTY = 'CREATE_PROPERTY';
export const SAVE_PROJECT_ID = 'SAVE_PROJECT_ID';

export const PROPERTY_TOURS_REPORT_REQUEST = 'PROPERTY_TOURS_REPORT_REQUEST';
export const PROPERTY_TOURS_REPORT_REQUEST_SUCCESS = 'PROPERTY_TOURS_REPORT_REQUEST_SUCCESS';
export const PROPERTY_TOURS_REPORT_REQUEST_FAILED = 'PROPERTY_TOURS_REPORT_REQUEST_FAILED';

export const PROPERTY_VISITS_REPORT_EXPORT_REQUEST = 'PROPERTY_VISITS_REPORT_EXPORT_REQUEST';
export const PROPERTY_VISITS_REPORT_EXPORT_REQUEST_SUCCESS = 'PROPERTY_VISITS_REPORT_EXPORT_REQUEST_SUCCESS';
export const PROPERTY_VISITS_REPORT_EXPORT_REQUEST_FAILED = 'PROPERTY_VISITS_REPORT_EXPORT_REQUEST_FAILED';
export const PROPERTY_VISITS_EXPORT_AND_PRINT_REQUEST = 'PROPERTY_VISITS_EXPORT_AND_PRINT_REQUEST';
export const PROPERTY_VISITS_EXPORT_REQUEST = 'PROPERTY_VISITS_EXPORT_REQUEST';
export const PROPERTY_VISITS_EXPORT_REQUEST_SUCCESS = 'PROPERTY_VISITS_EXPORT_REQUEST_SUCCESS';
export const PROPERTY_VISITS_EXPORT_REQUEST_FAILED = 'PROPERTY_VISITS_EXPORT_REQUEST_FAILED';

export const PROPERTIES_BY_BUILDER_REQUEST = 'PROPERTIES_BY_BUILDER_REQUEST';
export const PROPERTIES_BY_BUILDER_REQUEST_SUCCESS = 'PROPERTIES_BY_BUILDER_REQUEST_SUCCESS';
export const PROPERTIES_BY_BUILDER_REQUEST_FAILED = 'PROPERTIES_BY_BUILDER_REQUEST_FAILED';

export type PropertiesExportRequestActionType =
  ReturnType<typeof PropertiesActions.propertiesExportRequest>
  | ReturnType<typeof PropertiesActions.propertiesExportAndPrintRequest>;

export type PropertiesVisitsReportExportRequestActionType =
  | ReturnType<typeof PropertiesActions.propertyToursReportExportRequest | typeof PropertiesActions.propertyTourExportAndPrintRequest>;

export type PropertiesActionsTypes = ReturnType<ValueOf<typeof PropertiesActions>>;

export interface Property {
  property_id: number
  builder_id: number
  builder: string
  community:string
  subdivision: string
  lock_serial_number: string
  smart_lock_serial_number: string;
  lock_assigned: string
  lock_terminated: string
  house_num: string
  address_1: string
  city: string
  state: string
  price: number
  bedrooms: number
  halfBathrooms: number
  fullBathrooms: number
  squareFootage: number
  isModel: boolean
  disposition_id: string
  lockStatus: string
  smartLockStatus: string
  propStatus: string
  url_1: string
  rental_application_url: string
  client: string
  modified_on: string
  zipcode: string;
  adjustableTourHours: number
  tourHoursStart?: number
  tourHoursEnd?: number
  timezone: number
  hub_id: string | null
  hub_type: string | null;
  marketing_name: string | null;
  status?: string | null;
}

export interface PropertyDetailsInfo {
  address_1: string
  address_2: string
  builder: string
  builder_id: number
  city: string
  communityId: number
  communityName: string
  disposition_id: string
  disposition_id_value: string
  house_num: string
  latitude: number
  lock_assigned: string
  lock_serial_number: string
  smart_lock_serial_number: string
  smart_lock_type: string
  lock_status: number
  lock_status_value: string
  lock_terminated: string
  longitude: number
  lot_num: string
  price: number
  bedrooms: number
  halfBathrooms: number
  fullBathrooms: number
  squareFootage: number
  isModel: boolean
  property_id: number
  remarks: string
  state: string
  subdivision: string
  zipcode: string
  modified_on: string
  url_1: string
  rental_application_url: string
  url_image: string
  contracted_by: number
  contract_date: Date | null
  community_id?: number
  timezone: number
  timezoneAbbr: string
  adjustableTourHours: number
  tourHoursStart?: number
  tourHoursEnd?: number
  hub_id: string | null
  hub_type: string | null
  access_settings: boolean
  relock_interval: string | null
  end_of_day_lockup: string | null
  alarm?: boolean
  alarm_company?: string
  armed_hours?: string
  disarm_code?: string
  emergency_notes?: string
  marketing_name?: string
  novihome_integration?: string,
  temp_backup_code?: string,
  property_backup_code?: string,
  trade_code?: string;
  sales_code?: string;
  resident_code?: string;
  mls_number?: string;
  external_identifier?: string;
  external_identifier_2?: string;
  teams?: number[];
  agents?: Agent[];
  community_backup_code?: string,
  community_trade_code?: string;
  community_sales_code?: string;
  access_notes?: string;
}

export interface PropertyInput {
  property_id: number;
  builder: string;
  disposition_id?: string;
  house_num: string;
  address_1: string;
  subdivision: string;
  city: string;
  state: string;
  zipcode: string;
  latitude: number;
  longitude: number;
  community_id?: number | null;
  price: number;
  bedrooms: number;
  halfBathrooms: number;
  fullBathrooms: number;
  squareFootage: number;
  isModel: boolean;
  lock_serial_number?: string;
  smart_lock_serial_number?: string;
  lock_status?: number;
  contract_date?: Date | null;
  tourHoursStart?: number;
  tourHoursEnd?: number;
  timezone: number;
  url_1: string;
  rental_application_url: string;
  agents?: Agent[];
  LASSO_project_id?: string | null;
  propertyTourHours?: WeeklyTourHours[] | null;
  access_settings?: boolean;
  relock_interval?: string | null;
  end_of_day_lockup?: string | null;
  alarm?: boolean;
  alarm_company?: string;
  armed_hours?: string;
  disarm_code?: string;
  emergency_notes?: string;
  marketing_name?: string;
  lasso_community_project_id?: string,
  novihome_integration?: string,
  property_backup_code?: string,
  trade_code?: string,
  sales_code?: string,
  mls_number?: string;
  external_identifier?: string;
  external_identifier_2?: string;
  teams?: number[]
  enterpriseBuilder?: number
  access_notes?: string;
}

export interface PropertiesRequestSuccess {
  items: Property[]
  total: number
}

export interface PropertyDetailsVisit {
  cell_phone_1: string
  city: string
  customer_id: number
  customer_visit_id: number
  firstname: string
  lastname: string
  modified_on: string
  remarks: string
  visit_status_id: string
  visitor_status_id: number
  visitor_status_id_value: string
}

export interface PropertyDetailsAgent {
  agent_username: string
  is_project_manager: boolean | number
  firstname: string
  lastname: string
  email?: string
  phone?: string
}

export interface PropertyAccessSettings {
  accessSettings: boolean
  relockInterval: string | null
  endOfDayLockup: string | null
}

export interface PropertyImages {
  id: number
  property_id: number
  image_url: string
  sequence_position: number
  created_by: string
  modified_by: string
}

export interface PropertyDetails {
  info: PropertyDetailsInfo;
  agents: PropertyDetailsAgent[];
  visits: PropertyDetailsVisit[];
  teams: number[];
  integrations: IntegrationsInfo;
  communityTourHours: TourHoursType[];
  propertyTourHours: TourHoursType[];
  propertyImages: PropertyImages[];
}

interface IntegrationsInfoDetail {
  name: string
  project_id?: string | number | null
  isActive: boolean | number
}
export type IntegrationsInfo = [IntegrationsInfoDetail];

export interface DataToSave {
  integrations: IntegrationsInfo
  property_id: number
}

export interface PropertiesState {
  items: Property[]
  communityProperties: Property[]
  builderProperties: Property[]
  loading: boolean
  updating: boolean
  total: number
  error: ErrorMessage['message']
  selected: PropertyDetails | null
  propertyTours: any
}

export interface PropertyVisitReport {
  property_id: string;
  community_name: string
  address_1: string
  lot_num: number
  marketing_name: string
  no_of_tours: number
  avg_tours_per_day: number | string
  no_of_repeat_visitors: number | string
  days_since_activation: number
  days_since_last_tour: number
  top_toured_day: string
  top_toured_hour: string
}

import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { MimeType } from '../redux/types/mime';
import { ShareCustomer, UpdateCustomer } from '../redux/types/customers';

export const loadCustomers = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/customers${query}`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  });
};

export const loadCustomerById = (id: number) => axios.get(`/customers/${id}`);

export const loadCustomersExport = (
  selection: Array<number | string>,
  mimeType: MimeType,
) => {
  const headers = {
    Accept: mimeType,
  };

  return axios.post(
    '/customers/export',
    {
      selection,
    },
    { headers, responseType: 'blob' },
  );
};

export const updateCustomerById = (payload: UpdateCustomer) => axios.post(`/customers/${payload.customer_id}`, payload);

export const shareCustomer = (payload: ShareCustomer) => axios.post('/customers/share', payload);
